/* eslint-disable */
import React from 'react'
import { Link } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Page from '../../components/page'
import withRoot from '../../withRoot'

const styles = theme => ({
  list: {
    margin: theme.spacing(1),
  },
  listItem: {
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  paragraph: {
    margin: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
})

@withRoot
@withStyles(styles)
export default class TermOfUse extends React.Component {
  render() {
    const { classes } = this.props
    return (
        <Page toggleTheme={this.props.toggleTheme} title="FitFace Term of Use">

          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Overview</Typography>

          <Typography className={classes.paragraph} align="justify">
            The FitFace website located at https://fitface.xyz and all associated sites linked to fitface.xyz, its subsidiaries and affiliates (collectively, the “Site”) is a copyrighted work belonging to SystemD International LP.
            Throughout the site, the terms “we”, “us” and “our” refer to SystemD International LP.
            SystemD International LP offers this website, including all information, tools and services available from this site to you,
            the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound
            by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and
            conditions and policies referenced herein and/or available by hyperlink.
            These Terms of Service apply to all users of the site, including without limitation users who are browsers,
            vendors, customers, merchants, and/or contributors of content.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            Please read these Terms of Service carefully before accessing or using our website.
            By accessing or using any part of the site, you agree to be bound by these Terms of Service.
            If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.
            If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            Any new features or tools which are added to this website shall also be subject to the Terms of Service.
            You can review the most current version of the Terms of Service at any time on this page.
            We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website.
            It is your responsibility to check this page periodically for changes.
            Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
          </Typography>

          {/* SECTION 1 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Online Store Terms</Typography>

          <Typography className={classes.paragraph} align="justify">
            By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence,
            or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of
            your minor dependents to use this site.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            You must not transmit any worms or viruses or any code of a destructive nature.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            A breach or violation of any of the Terms will result in an immediate termination of your Services.
          </Typography>

          {/* SECTION 2 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">General Conditions</Typography>

          <Typography className={classes.paragraph} align="justify">
            We reserve the right to refuse service to anyone for any reason at any time.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service,
            or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
          </Typography>

          {/* SECTION 3 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Accuracy, completeness and timeliness of information</Typography>

          <Typography className={classes.paragraph} align="justify">
            We are not responsible if information made available on this site is not accurate, complete or current.
            The material on this site is provided for general information only and should not be relied upon or used
            as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information.
            Any reliance on the material on this site is at your own risk.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            This site may contain certain historical information. Historical information, necessarily,
            is not current and is provided for your reference only.
            We reserve the right to modify the contents of this site at any time,
            but we have no obligation to update any information on our site.
            You agree that it is your responsibility to monitor changes to our site.
          </Typography>

          {/* SECTION 4 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Modifications to the service and prices</Typography>

          <Typography className={classes.paragraph} align="justify">
            Prices for our products are subject to change without notice.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
          </Typography>

          {/* SECTION 5 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Products or Services</Typography>

          <Typography className={classes.paragraph} align="justify">
            Our products or services are available exclusively through the website.
            These products or services may have limited quantities and are subject to refund only according to our <Link className={classes.link} to='/agreements/refund'>Refund Policy</Link>.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We reserve the right, but we are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We may exercise this right on a case-by-case basis.
            We reserve the right to limit the quantities of any products or services that we offer.
            All descriptions of products or product pricing are subject to change at anytime without notice,
            at the sole discretion of us.
            We reserve the right to discontinue any product at any time.
            Any offer for any product or service made on this site is void where prohibited.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations,
            or that any errors in the Service will be corrected.
          </Typography>

          {/* SECTION 6 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Accuracy of billing and account information</Typography>

          <Typography className={classes.paragraph} align="justify">
            We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            For more detail, please review our <Link className={classes.link} to='/agreements/refund'>Refund Policy</Link>.
          </Typography>

          {/* SECTION 7 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Third-party links</Typography>

          <Typography className={classes.paragraph} align="justify">
            Certain content, products and services available via our Service may include materials from third-parties.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
          </Typography>

          {/* SECTION 8 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">User comments, feedback and other submissions</Typography>

          <Typography className={classes.paragraph} align="justify">
            If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, ‘comments’), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We take no responsibility and assume no liability for any comments posted by you or any third-party.
          </Typography>

          {/* SECTION 9 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Personal Information</Typography>

          <Typography className={classes.paragraph} align="justify">
            Your submission of personal information through the store is governed by our Privacy Policy. Please look at our <a className={classes.link} href="https://privacypolicies.com/privacy/view/c414516349cc2105a32db9afedc86288" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.
          </Typography>

          {/* SECTION 10 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Errors, Inaccuracies and Omissions</Typography>

          <Typography className={classes.paragraph} align="justify">
            Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
          </Typography>
          <Typography className={classes.paragraph} align="justify">
            We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
          </Typography>

          {/* SECTION 11 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Disclaimer of Warranties; Limitation of Liability</Typography>

          <Typography className={classes.paragraph} align="justify">
            SYSTEMD INERNATIONAL LP DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE.
            WE CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES.
            WE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            WE DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE AND/OR ANY OF OUR SERVICES.
            YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES.
            YOUR SOLE REMEDY AGAINST THE SITE FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT.
            THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
          </Typography>
          <Typography className={classes.paragraph} align="justify">
            We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
          </Typography>
          <Typography className={classes.paragraph} align="justify">
            You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
          </Typography>
          <Typography className={classes.paragraph} align="justify">
            You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
          </Typography>
          <Typography className={classes.paragraph} align="justify">
            In no case shall SystemD International LP, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
          </Typography>

          {/* SECTION 12 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Indemnity</Typography>

          <Typography className={classes.paragraph} align="justify">
            You agree to indemnify, defend and hold SystemD International LP and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
          </Typography>

          {/* SECTION 13 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Severability</Typography>

          <Typography className={classes.paragraph} align="justify">
            In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
          </Typography>

          {/* SECTION 14 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Termination</Typography>

          <Typography className={classes.paragraph} align="justify">
            The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
          </Typography>

          {/* SECTION 15 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Entire Agreement</Typography>

          <Typography className={classes.paragraph} align="justify">
            The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
          </Typography>

          {/* SECTION 16 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Governing Law</Typography>

          <Typography className={classes.paragraph} align="justify">
            These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Scotland – UK.
          </Typography>

          {/* SECTION 17 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Changes to Terms Of Service</Typography>

          <Typography className={classes.paragraph} align="justify">
            You can review the most current version of the Terms of Service at any time at this page.
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
          </Typography>

          {/* SECTION 18 */}
          <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Contact Information</Typography>

          <Typography className={classes.paragraph} align="justify">
            Questions about the Terms of Service should be sent to us at support@fitface.xyz
          </Typography>

          <Typography className={classes.paragraph} align="justify">
            SystemD International LP - 5 South Charlotte Street, Edinburgh EH2 4AN, Scotland, United Kingdom
          </Typography>

        </Page>
    )
  }
}
